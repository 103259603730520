import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import logo from 'assets/images/greenhouse_icon.png';
import React, {useEffect, useMemo} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';

import {
  useDeleteIntegration,
  useGetIntegration,
  useGetIntegrations,
  useUpdateIntegration,
} from '../../../../../services/integrationSevice';
import MainContent from './MainContent';

const connectedTabsData = (apiKey) => {
  return [
    {
      key: 'setup',
      title: 'Setup',
      route: `/administration/integrations/green-house/${apiKey}/setup`,
    },
    {
      key: 'settings',
      title: 'Settings',
      route: `/administration/integrations/green-house/${apiKey}/settings`,
    },
  ];
};
const disconnectedTabsData = [
  {
    key: 'setup',
    title: 'Setup',
    route: '/administration/integrations/green-house/setup',
  },
];

const GreenHouse = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {tab, id} = useParams();

  const {data, isLoading} = useGetIntegrations(!id);

  const integrationItem = data?.find((el) => el.name === 'greenhouse');

  useEffect(() => {
    if (integrationItem?.id && tab === 'setup') {
      navigate(
        `/administration/integrations/green-house/${integrationItem.id}/setup`,
      );
    }
  }, [integrationItem?.id, navigate, tab]);

  const getIntegration = useGetIntegration(id);

  const isConnected = !!getIntegration.data;
  const isActive = !!getIntegration.data?.isActive;

  const deleteIntegration = useDeleteIntegration(getIntegration.data?.id);
  const updateIntegration = useUpdateIntegration(getIntegration.data?.id);
  const handleDelete = () => {
    deleteIntegration.mutate(undefined, {
      onSuccess: () => {
        navigate('/administration/integrations');
      },
    });
  };

  const handleDisconnect = () => {
    updateIntegration.mutate(
      {
        isActive: !isActive,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['integration']);
        },
      },
    );
  };

  const tabsData = useMemo(
    () => (isConnected ? connectedTabsData(id) : disconnectedTabsData),
    [id, isConnected],
  );

  const activeKey = React.useMemo(() => {
    return tab ?? 'setup';
  }, [tab]);

  if (getIntegration.isLoading || isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          backTo={'/administration/integrations'}
          title={
            <span className='d-flex align-items-center flex-gap-2'>
              <img src={logo} alt='logo' style={{width: 40}} />
              <span>Greenhouse</span>
              {isActive && (
                <AppTag
                  color='success'
                  label='Connected'
                  shape={'circle'}
                  className={'tracking-normal ml-2 d-flex flex-items-center'}
                />
              )}
            </span>
          }
          category='Integration'
          extra={
            isConnected && (
              <div className='d-flex flex-gap-2'>
                <Button
                  type={!isActive ? 'primary' : ''}
                  onClick={handleDisconnect}>
                  {isActive ? 'Disconnect' : 'Connect'}
                </Button>
                <Button onClick={handleDelete}>Delete Integration</Button>
              </div>
            )
          }
          tabs={<AppDynamicTabs tabsData={tabsData} activeKey={activeKey} />}
        />
      }>
      <MainContent isConnected={isConnected} data={getIntegration.data} />
    </AppPageContainer>
  );
};

export default GreenHouse;
