import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import {Button, Typography} from 'antd';
import green from 'assets/images/GREENHOUSE_WORDMARK_GREEN.png';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {useGetIntegrations} from '../../../../services/integrationSevice';

const Integrations = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useGetIntegrations();
  const integration = data?.find((el) => el.name === 'greenhouse');

  const isConnected = useMemo(() => {
    if (data) {
      return !!integration;
    }
    return false;
  }, [data, integration]);

  if (isLoading) {
    return (
      <AppCard
        title='Applicant Tracking Systems'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 0}}>
        <AppLoader />
      </AppCard>
    );
  }

  return (
    <AppCard
      title='Applicant Tracking Systems'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 0}}>
      <AppCard
        style={{width: 340}}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img
          src={green}
          style={{width: 180, marginBottom: 10}}
          alt='greenhouse'
        />
        <Typography.Text style={{marginBottom: 10}}>
          Greenhouse is a complete hiring platform that helps you hire fairly
          and purposefully
        </Typography.Text>
        {isConnected ? (
          <Button
            type='primary'
            onClick={() => navigate(`green-house/${integration.id}/settings`)}>
            Manage
          </Button>
        ) : (
          <Button type='primary' onClick={() => navigate('green-house/setup')}>
            Connect
          </Button>
        )}
      </AppCard>
    </AppCard>
  );
};

export default Integrations;
