import Label from '@register-app/components/additional/label';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {message, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {MdContentCopy} from 'react-icons/md';

const ThirdStep = ({data}) => {
  const webUrl = `${process.env.REACT_APP_BASE_URL}/webhooks/${data.id}`;

  return (
    <>
      <Typography.Title level={4}>
        Step 3: Set Up Webhooks in Greenhouse
      </Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        Follow the instructions below to set up the required webhooks in
        Greenhouse in order for your integration to function properly and enable
        our advanced customizations.
      </Typography.Paragraph>
      <Typography.Title level={5}>Set Up Webhooks</Typography.Title>
      <ol style={{paddingLeft: 14}}>
        <li>{`Go to 'Configure' section on your Greenhouse application by clicking on 'cog' icon in the top right corner.`}</li>
        <li>{`On the left navigation menu, click on 'Dev Center' section and select 'API Credential Management' link.`}</li>
        <li>{`On the left navigation menu, click on 'Webhooks' section and select 'Webhooks' link.`}</li>
        <li>{`Type a name for this webhook, select when it should be triggered, and then copy 'Endpoint URL' and 'Secret Key' values from the fields below.`}</li>
        <li>{`Click on the 'Create Webhook' button and use the data below to create each webhook. You need to create 3 different webhooks: 'Job Created', 'Job Updated', and 'Job Deleted'.`}</li>
      </ol>
      <ul>
        <li>{`'Job Created' will allow Sourcer to know when a new job is created on Greenhouse`}</li>
        <li>{`'Job Updated' will allow Sourcer to sync the data of a job in case it gets updated on Greenhouse`}</li>
        <li>{`'Job Deleted' will allow Sourcer to remove the job from our system if it gets deleted on Greenhouse`}</li>
      </ul>
      <Typography.Paragraph>
        At least one of the webhooks is required to continue, however, we
        strongly recommend creating all of them in order for the integration to
        function properly.
      </Typography.Paragraph>
      <div style={{maxWidth: 500, marginBottom: 30}}>
        <Label text='Endpoint URL' />
        <div className='d-flex align-items-center flex-gap-2'>
          <AppInput
            placeholder='Enter Greenhouse API key'
            readOnly
            value={webUrl}
          />
          <MdContentCopy
            size={30}
            style={{
              cursor: 'pointer',
            }}
            onClick={async () => {
              await navigator.clipboard.writeText(webUrl);
              message.success(`Copied`);
            }}
          />
        </div>
      </div>
      <div style={{maxWidth: 500, marginBottom: 30}}>
        <Label text='Secret Key' />
        <div className='d-flex align-items-center flex-gap-2'>
          <AppInput
            placeholder='Enter Greenhouse API key'
            value={data?.integration.secret}
            readOnly
            type='password'
          />
          <MdContentCopy
            size={30}
            style={{
              cursor: 'pointer',
            }}
            onClick={async () => {
              await navigator.clipboard.writeText(data?.integration.secret);
              message.success(`Copied`);
            }}
          />
        </div>
      </div>
    </>
  );
};

ThirdStep.propTypes = {
  data: PropTypes.object,
};
export default ThirdStep;
