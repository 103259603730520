export const primarySupplierTabs = [{key: 'summary', title: 'Summary'}];

export const getPrimaryTabs = (tabView) => {
  const {
    isRating,
    isSkills,
    isEducation,
    isCourses,
    isCertifications,
    isLanguages,
    isScore,
    isNotes,
    isHistory,
  } = tabView;
  const tabs = [...primarySupplierTabs];
  isRating && tabs.push({key: 'performance-rating', title: 'Rating'});
  tabs.push({key: 'requisition', title: 'Requisition'});
  isHistory && tabs.push({key: 'employment', title: 'Employment'});
  isSkills && tabs.push({key: 'skills', title: 'Skills'});
  isEducation && tabs.push({key: 'eduction', title: 'Education'});
  isCourses && tabs.push({key: 'courses', title: 'Courses'});
  isCertifications &&
    tabs.push({key: 'certifications', title: 'Certifications'});
  isLanguages && tabs.push({key: 'languages', title: 'Languages'});
  isScore && tabs.push({key: 'score', title: 'Score'});
  isNotes && tabs.push({key: 'notes', title: 'Notes'});
  return tabs;
};
