import AppCard from '@wieldy/components/AppCard';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {Button, Col, Form, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from 'react-router-dom';

import {useUpdateIntegration} from '../../../../../../../services/integrationSevice';
import CopyInput from '../../common/copy-input';

const importOptions = [
  {
    value: true,
    label: 'Import all open jobs from Greenhouse',
  },
  {
    value: false,
    label: 'Import selected jobs from Greenhouse',
  },
];
const exportOptions = [
  {
    value: true,
    label: 'Export all candidates to Greenhouse',
  },
  {
    value: false,
    label: 'Export selected candidates to Greenhouse',
  },
];

const GreenHouseSettings = ({data}) => {
  const {id} = useParams();
  const updateIntegration = useUpdateIntegration(id);

  const webUrl = `${process.env.REACT_APP_BASE_URL}/webhooks/${data.id}`;
  const onUpdateKey = ({api_key}) => {
    const payload = {
      integration: {
        ...data.integration,
        apiKey: api_key,
      },
    };
    updateIntegration.mutate(payload);
  };

  const onUpdateImport = ({importAllJobs, exportAllCandidates}) => {
    const payload = {
      settings: {
        ...data.settings,
        importAllJobs,
        exportAllCandidates,
      },
    };
    updateIntegration.mutate(payload);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={14}>
        <AppCard title='Integration Settings'>
          <Typography.Paragraph>
            Manage integration settings for synchronizing jobs and candidates
            between your accounts
          </Typography.Paragraph>
          <Form
            onFinish={onUpdateImport}
            layout='vertical'
            colon={false}
            initialValues={data.settings}>
            <Row gutter={20} className='mb-5'>
              <Col span={12}>
                <Form.Item
                  name='importAllJobs'
                  label='Import Jobs from Greenhouse'>
                  <AppSelect
                    style={{
                      width: '100%',
                    }}
                    placeholder='Select import option'
                    options={importOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='exportAllCandidates'
                  label='Export Candidates to Greenhouse'>
                  <AppSelect
                    style={{
                      width: '100%',
                    }}
                    placeholder='Select export option'
                    options={exportOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              type='primary'
              htmlType='submit'
              disabled={updateIntegration.isLoading}>
              Save
            </Button>
          </Form>
        </AppCard>
      </Col>
      <Col span={10} className='d-flex flex-column flex-gap-6'>
        <AppCard title='API Key'>
          <Form
            onFinish={onUpdateKey}
            initialValues={{
              api_key: data.integration?.apiKey,
            }}
            layout='vertical'
            colon={false}>
            <Typography.Paragraph>
              If API key needs to be updated after the initial connection has
              been set up, enter key below and click ‘Reconnect’ to reestablish
              the connection
            </Typography.Paragraph>
            <Form.Item name='api_key' label='Greenhouse API key'>
              <AppInput />
            </Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={updateIntegration.isLoading}>
              Reconnect
            </Button>
          </Form>
        </AppCard>
        <AppCard title='Webhooks'>
          <Typography.Paragraph>
            Copy Endpoint URL and Secret Key to set up additional webhooks.
          </Typography.Paragraph>
          <CopyInput
            label='Endpoint URL'
            placeholder='Enter Endpoint URl'
            defValue={webUrl}
          />
          <CopyInput
            label='Secret Key'
            placeholder='Enter Secret Key'
            defValue={data.integration.secret}
            type='password'
          />
        </AppCard>
      </Col>
    </Row>
  );
};

GreenHouseSettings.propTypes = {
  data: PropTypes.object,
};

export default GreenHouseSettings;
