import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {Button} from 'antd';
import {CATEGORIES} from 'app/constants/index.js';
import {useAccountType} from 'app/hooks/useAccountType';
import {
  pagePrimaryTabsClient,
  pagePrimaryTabsSupplier,
  pageQueriesClient,
  pageQueriesSupplier,
  pageSecondaryTabsSupplier,
} from 'app/pages/main/requisitions/data.js';
import All from 'app/pages/main/requisitions/Requisitions/MainContent/All.js';
import Browse from 'app/pages/main/requisitions/Requisitions/MainContent/Browse.js';
import PageQueryProviderNew from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/requisitions/FilterRequisitions';
import React, {useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useGetIntegrations} from '../../../../services/integrationSevice';

const Requisitions = () => {
  const navigate = useNavigate();
  const {category} = useParams();
  const {isClient} = useAccountType();
  const {data} = useGetIntegrations();
  const isConnectedAts = data?.find((el) => el.name === 'greenhouse')?.isActive;

  const pageQueries = useMemo(
    () => (isClient ? pageQueriesClient : pageQueriesSupplier),
    [isClient],
  );

  const primaryTabs = useMemo(
    () =>
      isClient
        ? pagePrimaryTabsClient(isConnectedAts)
        : pagePrimaryTabsSupplier,
    [isClient, isConnectedAts],
  );

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  const activeKey = React.useMemo(() => {
    return category ?? CATEGORIES.ALL;
  }, [category]);

  useEffect(() => {
    if (!category) {
      navigate(CATEGORIES.ALL);
    }
  }, [category, navigate]);

  const currentPageParams = pageQueries[activeKey];
  const {title} = currentPageParams;

  if (!category) {
    return <AppLoader />;
  }

  return (
    <PageQueryProviderNew
      category={category}
      pageParams={pageQueries}
      filterConfig={isClient ? null : filterConfig}>
      <AppPageContainer
        header={
          <AppPageHeader
            title={title}
            category={'Requisitions'}
            ghost={false}
            tabs={
              <AppDynamicTabs tabsData={primaryTabs} activeKey={activeKey} />
            }
            extra={
              isClient ? (
                <Button
                  type='primary'
                  shape='round'
                  onClick={() => navigate('/requisitions/new-requisition')}>
                  Create New Requisition
                </Button>
              ) : (
                <AppDynamicTabs
                  className='tabs-secondary'
                  tabsData={pageSecondaryTabsSupplier}
                  activeKey={activeKey}
                />
              )
            }
          />
        }>
        {isAll ? <All /> : <Browse />}
      </AppPageContainer>
    </PageQueryProviderNew>
  );
};

export default Requisitions;
