import {Button, Space, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const SecondStep = ({isActive, isConnected, handleDisconnect}) => {
  const navigate = useNavigate();
  return (
    <>
      <Typography.Title level={4}>
        Step 2: Create Custom Field in Greenhouse
      </Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        If you prefer to automatically import all open jobs from Greenhouse into
        your Sourcer account, you can skip this section. If you prefer to select
        which Greenhouse jobs should be imported into your Sourcer account,
        follow the instructions below to implement a custom field to allow
        Greenhouse users to specify whether a job should be sent to Sourcer. You
        will also need to select the appropriate import option on Settings page
        after the initial connection has been established.
      </Typography.Paragraph>
      <Typography.Title level={5}>Create Custom Field</Typography.Title>
      <ol style={{paddingLeft: 14, marginBottom: 30}}>
        <li>{`Go to 'Configure' section on your Greenhouse application by clicking on 'cog' icon in the top right corner.`}</li>
        <li>{`On the left navigation menu, click on 'Custom Options' section and select 'Jobs' link under 'Company custom fields' section.`}</li>
        <li>{`Click on the 'Add field' button.`}</li>
        <li>{`Add a new custom field with 'Send job to Sourcer?' as the field name, and 'Yes/No' as the field type, the n n click 'Save'.`}</li>
      </ol>
      {!isConnected && (
        <>
          <Typography.Title level={5}>Establish Connection</Typography.Title>
          <Typography.Paragraph style={{marginBottom: 20}}>
            {`Once the API key has been entered and the custom field created (if needed), please click 'Connect' button below to establish the connection between your Greenhouse and Sourcer accounts.`}
          </Typography.Paragraph>
        </>
      )}
      {!isConnected &&
        (isActive ? (
          <Button style={{marginBottom: 40}} onClick={handleDisconnect}>
            Disconnect
          </Button>
        ) : (
          <Space style={{marginBottom: 40}}>
            <Button type='primary' htmlType='submit'>
              Connect
            </Button>
            <Button onClick={() => navigate('/administration/integrations')}>
              Cancel
            </Button>
          </Space>
        ))}
    </>
  );
};

SecondStep.propTypes = {
  isActive: PropTypes.bool,
  isConnected: PropTypes.bool,
  handleDisconnect: PropTypes.func,
};
export default SecondStep;
