import Label from '@register-app/components/additional/label';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {Form, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const FirstStep = ({data}) => {
  return (
    <>
      <Typography.Title level={4}>Step 1: Provide API Key</Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        Follow the instructions below and provide the API credentials needed to
        connect your Greenhouse and Sourcer accounts.
      </Typography.Paragraph>
      <Typography.Title level={5}>API Key Instructions</Typography.Title>
      <ol style={{paddingLeft: 14, marginBottom: 10}}>
        <li>{`Go to 'Configure' section on your Greenhouse application by clicking on 'cog' icon in the top right corner.`}</li>
        <li>{`On the left navigation menu, click on 'Dev Center' section and select 'API Credential Management' link.`}</li>
        <li>{`Click on the 'Create new API key' button.`}</li>
        <li>{`Select 'Harvest' API type, select 'Sourcer' under Partner field, enter a description, and click 'Manage permissions'.`}</li>
        <li>{`Copy the API key generated by Greenhouse and paste it in 'Greenhouse API Key' field below.`}</li>
        <li>{`Set the following permissions and click 'Save'.`}</li>
      </ol>
      <ul>
        <li>{`Applications > Get: Retrieve Application`}</li>
        <li>{`Applications > Get: List Application`}</li>
        <li>{`Candidates > Get: Retrieve Candidate`}</li>
        <li>{`Offers > Get: Retrieve Offer`}</li>
        <li>{`Jobs > Get: Retrieve Job`}</li>
        <li>{`Custom Fields > Get: Get custom fields`}</li>
      </ul>
      <div style={{maxWidth: 500, marginBottom: 30}}>
        <Label text='Greenhouse API Key' />
        <Form.Item
          initialValue={data?.integration?.apiKey}
          name='api_key'
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}>
          <AppInput
            readOnly={data?.isActive}
            placeholder='Enter Greenhouse API key'
          />
        </Form.Item>
      </div>
    </>
  );
};
FirstStep.propTypes = {
  data: PropTypes.object,
};
export default FirstStep;
